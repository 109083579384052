@import '../styles/app-colors';
@import '~@vg-constellation/styles/breakpoints';

.c11n-table {
  thead {
    font-size: 14px;

    tr {
      height: 80px;
    }

    th {
      padding-bottom: 10px;
    }
  }

  .c11n-table__tr:hover {
    .no-data-td {
      background: none;
    }
  }

  .c11n-table__thead-th--align-right {
    .mat-sort-header-container {
      justify-content: flex-end;
    }
  }

  border-collapse: separate;

  th:not(.sticky-right),
  td:not(.sticky-right) {
    border-right: 2px solid white; // white padding between the header cells
    min-width: 100px; // scroll aid
    white-space: initial;
  }

  .sticky-left {
    position: -webkit-sticky;
    position: sticky;
    left: 0;

    label {
      padding-right: 16px;
    }
  }

  .gradient-left::after {
    background: linear-gradient(to right, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) no-repeat scroll 0 center;
    background-size: 16px 100%;
    display: block;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    right: -14px;
    width: 16px;
    z-index: 100;
  }

  .sticky-right {
    width: 0;
    position: -webkit-sticky;
    position: sticky;
    right: 0;
    border: none;
    vertical-align: bottom;
    z-index: 1601;
  }

  .gradient-right::before {
    background-size: 16px 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 75%) no-repeat scroll 100% 0;
    display: block;
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    left: -14px;
    width: 16px;
    z-index: 100;
  }

  .scroll-left {
    position: absolute;
    right: -16px;
    bottom: 5px;
    width: 16px;
    height: 39px;
    background-color: black;
    border-radius: 8px 0 0 8px;
    background-image: url("../assets/img/caret-right.svg");
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    cursor: pointer;
    transform: rotate(180deg);
    z-index: 200;
  }

  .scroll-right {
    position: absolute;
    left: -15px;
    bottom: 5px;
    width: 16px;
    height: 39px;
    background-color: black;
    border-radius: 8px 0 0 8px;
    background-image: url("../assets/img/caret-right.svg");
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    cursor: pointer;
    z-index: 200;
  }

  .scroll-button {
    align-items: center;
    background-color: black;
    bottom: 5px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    z-index: 200;

    &.scroll-left {
      width: 16px;
      height: 39px;
      border-radius: 0 8px 8px 0;
      right: -16px;

      // TODO: request constellation to add `caret-left` icon
      svg {
        width: 0.875rem;
        height: 0.875rem;

        use {
          fill: currentcolor;
        }
      }
    }

    &.scroll-right {
      width: 16px;
      height: 39px;
      border-radius: 8px 0 0 8px;
      right: 0;
    }
  }

  @include media-breakpoint-down(lg) {
    .sticky-right {
      visibility: visible;
    }
  }

  @include media-breakpoint-up(lg) {
    .sticky-right {
      visibility: collapse;
    }
  }

  .c11n-table__thead-th.col-internal-highlight,
  .c11n-table__td.col-internal-highlight {
    background: $colors-internal-green-light;
  }

  .c11n-table__tr .c11n-table__td {
    font-weight: normal;
  }

  .c11n-table__tr:hover .c11n-table__td.col-internal-highlight {
    background: $colors-internal-green;
  }
}

@include media-breakpoint-down(lg) {

  .content,
  .recent-content {
    overflow: scroll;
  }
}