$colors-black: #000000;
$colors-white: #ffffff;
$colors-vanguard-red-bms: #96151d;
$colors-vanguard-red-medium: #7d1119;
$colors-vanguard-red-dark: #5e0d13;
$colors-link-active-inactive-red: #8d4900;
$colors-link-visited: #997a59;
$colors-cool-grey-10: #1a1a1a;
$colors-cool-grey-9: #333333;
$colors-cool-grey-8: #4d4d4d;
$colors-cool-grey-7-bms: #666666;
$colors-cool-grey-7: #696969;
$colors-cool-grey-6: #808080;
$colors-cool-grey-5: #999999;
$colors-cool-grey-4: #b3b3b3;
$colors-cool-grey-3: #cccccc;
$colors-cool-grey-2: #d9d9d9;
$colors-cool-grey-1: #e5e5e5;
$colors-warm-grey-6: #61584b;
$colors-warm-grey-5: #6e6454;
$colors-warm-grey-4: #7a6f5e;
$colors-warm-grey-3: #877b69;
$colors-warm-grey-2: #948775;
$colors-warm-grey-1-bms: #a8a093;
$colors-putty-5: #c2bdb1;
$colors-putty-4: #d4cfc3;
$colors-putty-3-bms: #e0dbd0;
$colors-putty-2: #eae5da;
$colors-putty-1: #efece3;
$colors-orange: #e37222;
$colors-lime: #a8b400;
$colors-aqua: #009aa6;
$colors-bright-blue: #0098db;
$colors-purple: #80379b;
$colors-brown: #5a311c;
$colors-olive: #9a8419;
$colors-green: #69923a;
$colors-dark-green: #006400;
$colors-teal: #006778;
$colors-slate: #49667d;
$colors-slate-blue: #44697d;
$colors-classic-navy: #003c69;
$colors-royal-blue-bonds: #005293;
$colors-internal-green: #bbcf9d;
$colors-internal-green-light: #d3e0c5;
$colors-internal-100: #f6f8f1;
$colors-internal-700: #708944;
$colors-messaging-error: #ff0000;
$colors-messaging-error-light: #dfb8bb;
$colors-messaging-info: #f0ab00;
$colors-messaging-info-light: #faf2bb;
$colors-search1: #b0a697;
$colors-search2: #d2cdc1;
$colors-yellow: #faf2bb;
$colors-warm-yellow: #ead99f;

$colors-pas-standard: #236578;
$colors-pas-dark: #1a5161;

$colors-market-ticker-positive: #5c8033;
$colors-market-ticker-negative: #da0000;
$colors-dynamic-image-border: #b2b2b2;

$colors-holdings-change-positive: #008a00;
$colors-holdings-change-negative: #da0000;
